/*
 * @Author: 魏鑫
 * @Date: 2020-01-09 15:18:19
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-14 16:34:23
 */
import axios from '@/lib/api.request'
// 获取数据
export const renewa = data =>
  axios.post('/hx/reportForm/renewa', data)
