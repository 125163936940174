
<template>
  <div class="content">
    <div class="title">
      <div class="t_date">
        <ul>
          <li @click="selectDate('1')" :class="select=='1'?'select':''"><span>年</span></li>
          <li @click="selectDate('2')" :class="select=='2'?'select':''"><span>月</span></li>
        </ul>
      </div>
      <div class="b_date">
        <div>
          <span>日期</span>
        </div>
        <div @click="showChangeDate">
          <i class="iconfont iconkaoqintongji" />
          <span>{{showyearmon}}</span>
          <md-icon name="arrow-down" size="md"></md-icon>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="item1 item3">
        <span class="num">{{renewa.ymount}}</span><br/>
        <span class="text">应收保费</span>
      </div>
      <div class="item1 item2">
        <span class="num">{{renewa.smount}}</span><br/>
        <span class="text">实收保费</span>
      </div>
    </div>
    <div class="chart">
      <div class="c_title">
        <span class="span">机构占比</span>
      </div>
       <ul>
         <li @click="selecttjcode('1')" :class="isgb=='1'?'select':''">应收保费</li>
         <li @click="selecttjcode('2')" :class="isgb=='2'?'select':''">实收保费</li>
        </ul>
      <ve-ring :data="chartData" width="356.25px"  :settings="chartSettings" :extend="tjextend" height="400px" class="imgstyle"></ve-ring>
    </div>
    <div class="chart">
      <div class="c_title">
        <span class="span">机构排名</span>
      </div>
       <ul>
         <li @click="selectranking('1')" :class="isranking=='1'?'select':''">应收保费</li>
         <li @click="selectranking('2')" :class="isranking=='2'?'select':''">实收保费</li>
        </ul>
      <ve-bar :data="chartDatas" width="356.25px"  :settings="chartSettings" height="400px" :extend="extend" class="imgstyle"></ve-bar>
    </div>
    <!-- <div class="chart">
      <div class="c_title">
        <span class="span">续期详情</span>
      </div>
       <ul>
         <li @click="selectranking('1')" :class="isranking=='1'?'select':''">机构</li>
         <li @click="selectranking('2')" :class="isranking=='2'?'select':''">保险公司</li>
        </ul>
    </div> -->
    <md-date-picker
      ref="datePicker"
      v-model="isShowData1"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData2"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
  </div>
</template>
<script>
import { renewa } from '@/api/smarkboard/renewalreport/index'
export default {
  data () {
    this.chartSettings = {
      labelMap: {
        ymount: '应收保费',
        // proportionmount: '规保占比',
        smount: '实收保费',
        mountproportion: '保费占比',
        tjcode: '机构号',
        tjcodename: '机构名称'
      }
    }
    this.tjextend = {
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 0,
        top: 10,
        bottom: 20
      },
      grid: {
        right: 100
      }
    }
    // 设置x轴倾斜角度
    this.extend = {
      'xAxis.0.axisLabel.rotate': 25
    }
    return {
      currentDate: new Date(),
      isShowData: false,
      isShowData1: false,
      isShowData2: false,
      select: '1',
      isgb: '1',
      isranking: '1',
      yearmon: '',
      showyearmon: '',
      renewa: {
        smount: '',
        ymount: ''
      },
      smountInfo: {},
      ymountInfo: {},
      chartData: {
        columns: [ 'tjcodename', 'ymount', 'mountproportion' ],
        rows: []
      },
      chartDatas: {
        columns: [ 'tjcodename', 'ymount' ],
        rows: []
      }
    }
  },
  created () {
    var d = new Date()
    var year = d.getFullYear()
    this.yearmon = `${year}`
    this.showyearmon = `${year}年`
    this.getData()
  },
  methods: {
    onDatePickerConfirm (columnsValue) {
      let aaa = []
      let bbb = []
      for (var i = 0; i < columnsValue.length; i++) {
        aaa.push(columnsValue[i].text)
        bbb.push(columnsValue[i].value)
      }
      this.showyearmon = aaa.join('')
      this.yearmon = bbb.join('-')
      this.getData()
    },
    selectDate (val) {
      this.select = val
      var d = new Date()
      var year = d.getFullYear()
      var month = (d.getMonth() + 1)
      // month > 9 ? month : month = '0' + month
      var day = d.getDate()
      if (val == '1') {
        this.yearmon = `${year}`
        this.showyearmon = `${year}年`
      } else {
        this.yearmon = `${year}-${month}`
        this.showyearmon = `${year}年${month}月`
      }
      this.getData()
    },
    // 切换机构占比是规保还是标保
    selecttjcode (val) {
      this.isgb = val
      if (val === '1') {
        this.chartData.columns = [ 'tjcodename', 'ymount', 'mountproportion' ]
        this.chartData.rows = this.ymountInfo
      } else {
        this.chartData.columns = [ 'tjcodename', 'smount', 'mountproportion' ]
        this.chartData.rows = this.smountInfo
      }
    },
    // 切换机构排名是规保还是标保
    selectranking (val) {
      this.isranking = val
      if (val === '1') {
        this.chartDatas.columns = [ 'tjcodename', 'ymount' ]
        this.chartDatas.rows = this.ymountInfo
      } else {
        this.chartDatas.columns = [ 'tjcodename', 'smount' ]
        this.chartDatas.rows = this.smountInfo
      }
    },
    showChangeDate () {
      if (this.select == '1') {
        this.isShowData1 = true
      } else if (this.select == '2') {
        this.isShowData2 = true
      }
    },
    getData () {
      let data = {}
      if (this.select === '1') {
        data = {
          year: this.yearmon
        }
      } else {
        data = {
          month: this.yearmon
        }
      }
      renewa(data).then(res => {
        let Data = res.data.data
        this.renewa = Data.renewa
        this.smountInfo = Data.smountInfo
        this.ymountInfo = Data.ymountInfo
        this.chartData.rows = Data.ymountInfo
        this.chartDatas.rows = Data.ymountInfo
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  height 100%;
  overflow-y: hidden;
  background-color #fff
  .title {
    border-bottom 30px solid #F5F6F8
    .t_date {
      border-bottom 1px solid #EEEEEE
      ul {
        width: 55%;
        overflow: hidden;
        margin: 60px auto;
        text-align center
        li {
          width: 50%;
          display: block;
          float: left;
          border 1px solid #EEEEEE
          padding-top 10px
          padding-bottom 10px
          span {
            font-size:35px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(56,56,56,1);
          }
        }
        .select {
          border 1px solid #228ACE
          background-color #E6F2FC
        }
      }
    }
    .b_date {
      display flex
      justify-content space-between
      padding 30px 70px
      span {
        font-size:40px;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(142,142,144,1);
      }
    }
  }
  .middle {
    display flex
    justify-content center
    border-bottom 30px solid #F5F6F8
    .item1 {
      width 35%
      text-align center
      padding-top 50px
      padding-bottom 50px
      .num {
        font-size:45px;
        font-family:Bahnschrift;
        font-weight:400;
        color:rgba(68,68,68,1);
      }
      .text {
        font-size:30px;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(153,153,153,1);
      }
    }
    .item2 {
      border-left 1px solid #EEEEEE
      // border-right 1px solid #EEEEEE
    }
    .item3{
      // border-left 1px solid #EEEEEE
    }
  }
  .chart {
    border-bottom 30px solid #F5F6F8
    width: 100%
    .c_title {
      padding 30px 80px
      border-bottom 1px solid #EEEEEE
      .span {
        font-size:48px;
        font-family:PingFang SC;
        font-weight:bold;
        color:rgba(61,61,61,1);
      }
    }
    ul {
        width: 55%;
        overflow: hidden;
        margin: 60px auto;
        text-align center
        li {
          width: 50%;
          display: block;
          float: left;
          border 1px solid #EEEEEE
          padding-top 10px
          padding-bottom 10px
          span {
            font-size:35px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(56,56,56,1);
          }
        }
        .select {
          border 1px solid #228ACE
          background-color #E6F2FC
        }
      }
  }
}
.imgstyle{
  margin 0px auto
}
</style>
